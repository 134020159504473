import {ButtonVariant, InhaberEntitiesSelectors, NavigationService, RouterSelectors} from '@adnova/jf-ng-components';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {Router} from '@angular/router';
import {NavigationLink} from './navigation-link.interface';
import {IconName} from '@adnova/jf-ng-components/lib/generic/media/icon/icons';


@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent implements OnInit, OnDestroy {

  private readonly _destroyed$ = new Subject<void>();

  private _buttonLabel = '';

  private _buttonRoute = '';

  private _buttonIcon?: IconName;

  private _buttonVariant: ButtonVariant;

  private _navigationLinks: NavigationLink[] = [];

  protected inhaberId?: string;

  constructor(
    private readonly navigationService: NavigationService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  get buttonLabel(): string {
    return this._buttonLabel;
  }

  set buttonLabel(value: string) {
    this._buttonLabel = value;
  }

  get buttonRoute(): string {
    return this._buttonRoute;
  }

  set buttonRoute(value: string) {
    this._buttonRoute = value;
  }

  get buttonIcon(): IconName | undefined {
    return this._buttonIcon;
  }

  set buttonIcon(value: IconName | undefined) {
    this._buttonIcon = value;
  }

  get buttonVariant(): ButtonVariant {
    return this._buttonVariant;
  }

  set buttonVariant(value: ButtonVariant) {
    this._buttonVariant = value;
  }

  get navigationLinks(): NavigationLink[] {
    return this._navigationLinks;
  }

  set navigationLinks(value: NavigationLink[]) {
    this._navigationLinks = value;
  }

  ngOnInit(): void {
    this.store.select(RouterSelectors.dataByKey('buttonLabel')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonLabel) => {
      this.buttonLabel = buttonLabel || '';
    });

    this.store.select(RouterSelectors.dataByKey('buttonRoute')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonRoute) => {
      this.buttonRoute = buttonRoute || '';
    });

    this.store.select(RouterSelectors.dataByKey<IconName>('buttonIcon')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonIcon) => {
      this.buttonIcon = buttonIcon;
    });

    this.store.select(RouterSelectors.dataByKey<ButtonVariant>('buttonVariant')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonVariant) => {
      this.buttonVariant = buttonVariant;
    });

    this.store.select(RouterSelectors.dataByKey<NavigationLink[]>('navigationLinks')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((navigationLinks) => {
      if (navigationLinks) {
        this.navigationLinks = navigationLinks;
      } else {
        this.navigationLinks = [];
      }
    });

    combineLatest([
      this.store.select(InhaberEntitiesSelectors.currentInhaberId),
      this.store.select(InhaberEntitiesSelectors.refInhaberId),
      this.store.select(InhaberEntitiesSelectors.fullInhaberList),
    ]).pipe(
      takeUntil(this._destroyed$),
    ).subscribe(([currentInhaberId, refInhaberId, fullInhaberList]) => {
      if (currentInhaberId) {
        this.inhaberId = currentInhaberId;
      } else if (refInhaberId) {
        this.inhaberId = refInhaberId;
      } else if (fullInhaberList.length > 0) {
        this.inhaberId = fullInhaberList[0].id;
      }
    });
  }


  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  buttonNavigation(): void {
    if (this.buttonRoute) {
      this.router.navigateByUrl(this.buttonRoute.replace(':inhaber', this.inhaberId || ''));
    }
  }

}
