import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AppNavigationComponent} from './modules/pages/app-navigation/app-navigation.component';
import {NavigationLink} from './modules/pages/app-navigation/navigation-link.interface';


/** INFO:
 * Die BetriebsauswahlPage muss über der Ebene der Routes liegen,
 * in der Sie eingebunden sein soll, um nicht bei jedem Navigationsschritt neu geladen zu werden.
 *
 */

const routes: Routes = [
  {
    path: '',
    component: AppNavigationComponent,
    // TODO AuthGuard entfernen
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'legal/impressum',
      },
      {
        path: 'legal/impressum',
        data: {
          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
          // TODO buttonRoute: '', // INFO: Route des Zur-Übersicht-Buttons. Das Fragment ":inhaber" wird durch die aktuelle Inhaber-ID ersetzt.
          buttonIcon: 'PaginationLeft', // INFO: Icon für den Zur-Übersicht-Button.
          buttonVariant: 'ghost' // INFO: Variante für den Zur-Übersicht-Button.
        },
        loadComponent: () => import('./modules/routes/legal/impressum/impressum.component').then(m => m.ImpressumComponent),
      },
    ]
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    }
  )],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
